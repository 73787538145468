import { ReactElement, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import config from '@breathelife/config/frontend';
import { ProductName, setMonitoringProduct } from '@breathelife/monitoring-frontend';
import { FocusControl } from '@breathelife/ui-components';

import DynamicPdfContainer from './DynamicPdf/DynamicPdfContainer';
import LeadPlatformContainer from './LeadPlatform/LeadPlatformContainer';
import PlanFinder from './PlanFinder';

const Urls = {
  planFinder: {
    home: '/plan/*',
  },
  leadPlatform: {
    pro: '/pro/*',
  },
  pdf: '/pdf/*',
};

function ConsumerFlow(): ReactElement {
  setMonitoringProduct(ProductName.ConsumerFlow);
  return <PlanFinder />;
}

function Home(): ReactElement {
  setMonitoringProduct(ProductName.LeadPlatform);
  return <LeadPlatformContainer />;
}

function ScrollToTop(): null {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

export function Root(): ReactElement {
  const hasCXFlow = config.get<boolean>('features.consumerFlow.enabled') ?? false;

  return (
    <FocusControl>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {hasCXFlow && <Route path={Urls.planFinder.home} element={<ConsumerFlow />} />}
          <Route path={Urls.leadPlatform.pro} element={<Home />} />
          <Route path={Urls.pdf} element={<DynamicPdfContainer />} />
          <Route
            path='*'
            element={<Navigate to={hasCXFlow ? Urls.planFinder.home : Urls.leadPlatform.pro} replace />}
          />
        </Routes>
      </BrowserRouter>
    </FocusControl>
  );
}
