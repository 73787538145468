export const sensitiveDataLabels = [
  // Personal information
  ['date', 'of', 'birth'],
  ['first', 'name'],
  ['last', 'name'],

  // Contact information
  ['email'],
  ['phone', 'number'],

  // Address
  ['street', 'number'],
  ['street', 'address'],
  ['apartment', 'number'],

  // Financial information
  ['credit', 'card', 'number'],
  ['credit', 'card', 'id'],
  ['transit', 'code'],
  ['institution', 'number'],
  ['account', 'number'],

  // Identification numbers
  ['social', 'security', 'number'],
  ['social', 'identification', 'number'],
  ['social', 'insurance', 'number'],

  // Identification documents
  ['driver', 'license'],
  ['driver', 'license', 'id'],
  ['passport', 'number'],
  ['passport', 'id'],
  ['health', 'card', 'number'],
  ['health', 'card', 'id'],
];
