import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { Location, Action } from 'history';

import { hash } from '@breathelife/hash';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import { Language } from '@breathelife/types';

const TRACKED_ROUTER_ACTIONS = ['@@router/LOCATION_CHANGE'];

type LocationChangePayload = {
  location: Location;
  action: Action;
  isFirstRendering: boolean;
};

function getLanguageValueFromStorage(): Language | undefined {
  const storedLocaleValue: string | null = window.localStorage.getItem('locale');

  if (storedLocaleValue && Object.keys(Language).includes(storedLocaleValue)) {
    return storedLocaleValue as Language;
  }

  return undefined;
}

export function createTrackerMiddleware<TStore>(
  isTrackedPage: (path: string) => boolean,
  getApplicationId: (store: TStore) => string | null,
  fallbackLanguage: Language,
): Middleware {
  return (api: MiddlewareAPI) => (next: Dispatch) => (action: PayloadAction<LocationChangePayload>) => {
    if (TRACKED_ROUTER_ACTIONS.includes(action.type) && isTrackedPage(action.payload.location.pathname)) {
      const store: TStore = api.getState();
      const applicationId = getApplicationId(store);

      const language = getLanguageValueFromStorage() || fallbackLanguage;

      TypewriterTracking.page({
        hashedId: hash(applicationId),
        language,
      });
    }

    return next(action);
  };
}
