import { ReactElement, useEffect, useState } from 'react';

import featureFlag, { FeatureFlag } from '@breathelife/feature-flag';

import ConsumerFlowContainer from '../ConsumerFlow/ConsumerFlowContainer';

export default function (): ReactElement | null {
  const [enableHeaderProductSummary, setEnableHeaderProductSummary] = useState(false);
  const isInitialized = featureFlag.isInitialized();

  useEffect(() => {
    if (!isInitialized) return;

    setEnableHeaderProductSummary(featureFlag.get(FeatureFlag.enableHeaderProductSummary));
  }, [isInitialized]);

  return <ConsumerFlowContainer enableHeaderProductSummary={enableHeaderProductSummary} />;
}
