import { LeakResult } from './leakResult';

const MAX_RECURSION_DEPTH = 30;
const MAX_RECURSION_DEPTH_REACHED_MESSAGE = 'MaxLeakSensorScanDepthReached';
const INVALID_SCAN_TYPE_MESSAGE = 'InvalidScanType';

export class LeakSensor {
  constructor(
    private knownSensitiveData: string[][] = [[]],
    private sensitiveDataLabels: string[][] = [[]],
  ) {}

  public check(data: any): LeakResult {
    const foundSensitiveLabels: string[] = [];
    const foundSensitiveData: string[] = [];

    if (this.knownSensitiveData.length || this.sensitiveDataLabels.length) {
      return this.scanObject(data, this.sensitiveDataLabels, this.knownSensitiveData, 0);
    }

    return { foundSensitiveLabels, foundSensitiveData };
  }

  private scanObject(data: unknown, sensitiveKeys: string[][], sensitiveValues: string[][], depth: number): LeakResult {
    const foundSensitiveLabels: string[] = [];
    const foundSensitiveData: string[] = [];

    const scannableTypes = ['string', 'object'];

    if (!data || !scannableTypes.includes(typeof data)) {
      // We exit early if the data does not exist, or is not a string, array, or object
      return { foundSensitiveLabels, foundSensitiveData };
    } else if (depth >= MAX_RECURSION_DEPTH) {
      // We have reached the maximum recursion depth
      foundSensitiveLabels.push(MAX_RECURSION_DEPTH_REACHED_MESSAGE);
    } else if (typeof data === 'string') {
      // We have a leaf value, we need scan it for sensitive data
      foundSensitiveData.push(...this.scanString(data, sensitiveValues));
    } else if (Object.keys(data).length >= 0 || Array.isArray(data)) {
      // We have an object to iterate through
      for (const [key, value] of Object.entries(data)) {
        foundSensitiveLabels.push(...this.scanString(key, sensitiveKeys));

        const { foundSensitiveLabels: labels, foundSensitiveData: values } = this.scanObject(
          value,
          sensitiveKeys,
          sensitiveValues,
          depth + 1,
        );

        foundSensitiveLabels.push(...labels);
        foundSensitiveData.push(...values);

        // Break early if we've encountered an error
        if (
          [MAX_RECURSION_DEPTH_REACHED_MESSAGE, INVALID_SCAN_TYPE_MESSAGE].some(
            (message) => foundSensitiveData.includes(message) || foundSensitiveLabels.includes(message),
          )
        ) {
          break;
        }
      }
    } else {
      // We should not reach this point.
      foundSensitiveLabels.push(INVALID_SCAN_TYPE_MESSAGE);
    }

    return { foundSensitiveLabels, foundSensitiveData };
  }

  private scanString(origValue: string, keywords: string[][]): string[] {
    if (!origValue) {
      return [];
    }

    const foundKeywords: string[] = [];
    const value = origValue.toLowerCase();

    for (const keyword of keywords) {
      let found = true;
      let lastPosition = 0;
      for (const keywordPart of keyword) {
        const newPosition = value.indexOf(keywordPart, lastPosition);
        if (newPosition === -1) {
          found = false;
          break;
        } else {
          lastPosition = newPosition;
        }
      }
      if (found) {
        foundKeywords.push(keyword.join(' '));
      }
    }
    return foundKeywords;
  }
}
