import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProgressState = {
  progress: number;
};

type ProgressPayload = {
  progress: number;
};

type ActionsType = {
  setProgress: (state: ProgressState, { payload }: PayloadAction<ProgressPayload>) => void;
};

const defaultState = {
  progress: 0,
};

export const progressSlice = createSlice<ProgressState, ActionsType>({
  name: 'progress',
  initialState: defaultState,
  reducers: {
    setProgress(state: ProgressState, { payload }: PayloadAction<ProgressPayload>) {
      state.progress = payload.progress;
    },
  },
});
