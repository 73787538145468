import { useEffect, useState } from 'react';

const DATA_SCRIPT_ID_ATTRIBUTE_KEY = 'data-script-id';

export enum ExternalScriptDomElementPosition {
  head = 'head',
  body = 'body',
}

type BaseScript = {
  id: string;
  domElementPosition: ExternalScriptDomElementPosition;
};

type BaseScriptTagAttributes = {
  src: string;
  async?: boolean;
  type?: string;
  custom?: CustomScriptTagAttributes;
};

type CustomScriptTagAttributes = Record<string, boolean | string>;

export type ExternalUrlScript = BaseScript & {
  attributes: BaseScriptTagAttributes;
  setLibOnLoad?: boolean;
  onLoadLibName?: string;
};

export type ExternalHtmlScriptTag = BaseScript & { scriptTag: string };

/**
 *
 * @param {ExternalHtmlScriptTag[]} scripts list of Html stringified script tag to load. It can either be a script or noscript tag
 * @returns { isLoaded: boolean } if the script is loaded or not in the dom
 */
export function useExternalHtmlScriptTags(scripts: ExternalHtmlScriptTag[]): { isLoaded: boolean } {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (scripts.length === 0) {
      return;
    }

    scripts.forEach((script) => {
      const { domElementPosition, scriptTag, id } = script;

      const existingScriptElement = document.querySelector(`script[${DATA_SCRIPT_ID_ATTRIBUTE_KEY}=${id}]`);
      const existingNoScriptElement = document.querySelector(`noscript[${DATA_SCRIPT_ID_ATTRIBUTE_KEY}=${id}]`);

      if (existingScriptElement || existingNoScriptElement) {
        // script are already in dom, let's not load them again
        setIsLoaded(true);
        return;
      }

      const domElement = document.querySelector(domElementPosition);
      if (!domElement) {
        throw new Error(`Element '${domElementPosition}' does not exist to append the HTML script element`);
      }

      const parsedHtml = document.createRange().createContextualFragment(scriptTag);
      const parsedHtmlScript = parsedHtml.querySelector('script');
      const parsedHtmlNoScript = parsedHtml.querySelector('noscript');

      if (parsedHtmlScript) {
        parsedHtmlScript.setAttribute(DATA_SCRIPT_ID_ATTRIBUTE_KEY, id);
      }

      if (parsedHtmlNoScript) {
        parsedHtmlNoScript.setAttribute(DATA_SCRIPT_ID_ATTRIBUTE_KEY, id);
      }

      domElement.appendChild(parsedHtml);
    });
    setIsLoaded(true);
  }, [scripts]);

  return { isLoaded };
}

/**
 * Allow to simply load a script and also return the lib loaded from window when requested
 * @param {ExternalUrlScript} script details to be loaded
 * @returns  { isLibLoaded: boolean; lib: T | undefined; isScriptLoaded: boolean } if the script is loaded or not and the lib with it's loading state when requested
 */
export function useExternalScriptUrl<T = unknown>(
  script: ExternalUrlScript,
): { isLibLoaded: boolean; lib: T | undefined; isScriptLoaded: boolean } {
  const [libState, setLibState] = useState<{ isLoaded: boolean; lib: T | undefined }>({
    isLoaded: false,
    lib: undefined,
  });
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    if (!isScriptLoaded) return;

    const { setLibOnLoad, onLoadLibName } = script;

    if (!setLibOnLoad || !onLoadLibName) return;

    //@ts-ignore window doesn't know the type of the lib
    const lib = window[onLoadLibName];

    if (lib) {
      setLibState({ isLoaded: true, lib });
      return;
    }

    const checkScriptLoadedInterval = setInterval(() => {
      //@ts-ignore window doesn't know the type of the lib
      const lib = window[onLoadLibName];
      if (lib) {
        setLibState({ isLoaded: true, lib });
        clearInterval(checkScriptLoadedInterval);
      }
    }, 200);

    return () => {
      clearInterval(checkScriptLoadedInterval);
    };
  }, [isScriptLoaded]);

  useEffect(() => {
    if (!script) return;

    const { id, domElementPosition, attributes, setLibOnLoad, onLoadLibName } = script;
    const shouldSetLibOnLoad = setLibOnLoad && onLoadLibName;
    const isLoadedInWindow = shouldSetLibOnLoad && isInWindow(onLoadLibName);

    // If the lib is already loaded in window, no need to try to add a new script or to check if it exist
    if (isLoadedInWindow) {
      setIsScriptLoaded(true);
      return;
    }

    const existingScriptElement = document.querySelector(`script[${DATA_SCRIPT_ID_ATTRIBUTE_KEY}=${id}]`);
    // script already exist, no need to try to re-add it
    if (existingScriptElement) {
      setIsScriptLoaded(true);
      return;
    }

    const domElement = document.querySelector(domElementPosition);
    if (!domElement) {
      throw new Error(`Element '${domElementPosition}' does not exist to append the HTML script element`);
    }

    const newScriptElement = document.createElement('script');

    newScriptElement.src = attributes.src;
    newScriptElement.setAttribute(DATA_SCRIPT_ID_ATTRIBUTE_KEY, id);
    newScriptElement.async = !!attributes.async;
    if (attributes.type) newScriptElement.type = attributes.type;

    const customAttributes = attributes.custom;
    if (customAttributes) {
      Object.keys(customAttributes).forEach((key) => {
        newScriptElement.setAttribute(key, customAttributes[key] as string);
      });
    }

    domElement.appendChild(newScriptElement);
    setIsScriptLoaded(true);
  }, [script]);

  return { isLibLoaded: libState.isLoaded, lib: libState.lib, isScriptLoaded };
}

function isInWindow(libname: string): boolean {
  // @ts-ignore window doesn't know the type of the lib
  return !!window[libname];
}
