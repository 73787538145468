type Metadata = Record<string, unknown>;
type Message = string | Error;

// Base error class. All custom errors should extend this.
export class BaseError extends Error {
  metadata: Metadata | undefined;

  constructor(message: Message, metadata?: Metadata, options?: ErrorOptions) {
    // Allow errors to wrap other errors and inherit their message
    if (message instanceof Error) {
      super(message.message, options);
    } else {
      super(message, options);
    }

    this.metadata = this.metadata ?? metadata;

    // Adds additional path to stack track that includes this file.
    // Works similarly to new Error().stack.
    Error.captureStackTrace(this.constructor);

    // Make sure prototype of new class is set correctly
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
