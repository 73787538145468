// Disabling the unused-vars because it's easier to reason about with the arguments.
/* eslint-disable @typescript-eslint/no-unused-vars */

interface IOption<A> {
  andThen<B>(f: (a: A) => Option<B>): Option<B>;

  map<B>(f: (a: A) => B): Option<B>;

  withDefault<C>(fallback: C): A | C;
}

export type Option<A> = Some<A> | None<A>;

class Some<A> implements IOption<A> {
  public readonly tag: 'Some';
  public readonly value: A;

  constructor(value: A) {
    this.tag = 'Some';
    this.value = value;
  }

  hasValue = (): this is Some<A> => true;

  map<B>(f: (a: A) => B): Option<B> {
    return some(f(this.value));
  }

  andThen<B>(f: (a: A) => Option<B>): Option<B> {
    return f(this.value);
  }

  withDefault<C>(_fallback: C): A | C {
    return this.value;
  }
}

class None<A> implements IOption<A> {
  public readonly tag = 'None';

  hasValue = (): this is Some<A> => false;

  map<B>(f: (a: A) => B): Option<B> {
    return none();
  }

  andThen<B>(f: (a: A) => Option<B>): Option<B> {
    return none();
  }

  withDefault<C>(fallback: C): A | C {
    return fallback;
  }
}

export function some<A>(value: A): Option<A> {
  return new Some(value);
}

export function none(): Option<never> {
  return new None();
}

export const isSome = <A>(option: Option<A>): option is Some<A> => option.tag === 'Some';
export const isNone = <A>(option: Option<A>): option is None<A> => option.tag === 'None';
