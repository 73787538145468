import { RumEvent } from '@datadog/browser-rum';
import { RumEventError } from '../types';

// The objective of this function is to prevent PII/PHI leaks
// We only allow sending to DataDog error events that are part of the allowedSources list
function shouldAllowEvent(event: RumEvent): boolean {
  if (!event.error) {
    return true;
  }

  // This is coming from RumErrorEvent type in https://www.npmjs.com/package/@datadog/browser-rum-core
  const allowedSources: RumEventError['source'][] = [
    'network',
    'source',
    'logger',
    'agent',
    'webview',
    'custom',
    'report',
  ];

  const { source } = event.error as RumEventError;

  return allowedSources.some((s) => s === source);
}

export function beforeSend(event: RumEvent): boolean {
  if (!shouldAllowEvent(event)) {
    return false;
  }
  return true;
}
