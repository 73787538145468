import { EventHint } from '@sentry/browser';

import { MessageFilterConfig } from '@breathelife/types';

import { EventModifierReturnType } from '../types';
import { toSeverityLevel } from '../utils';

function extractMessageFromHint(hint?: EventHint): string | undefined {
  if (hint === undefined || hint.originalException === null || hint.originalException === undefined) {
    return;
  }

  if (typeof hint.originalException === 'string') {
    return hint.originalException;
  }

  return (hint.originalException as Error).message;
}

export function messageFilter(
  hint: EventHint | undefined,
  messageFilterConfig: MessageFilterConfig[],
): EventModifierReturnType {
  const message = extractMessageFromHint(hint);
  if (message === undefined) {
    return;
  }

  const filter = messageFilterConfig.find((filter) => {
    return message.includes(filter.message);
  });

  if (filter === undefined) {
    return;
  }

  return {
    fingerprint: filter.fingerprint,
    severity: toSeverityLevel(filter.severity),
  };
}
