import { LeakSensor, LeakSensorBLCode, LEAK_SENSOR_DETECTED_MESSAGE } from '@breathelife/monitoring-common';
import { Event } from '@sentry/browser';

export function sanitize(event: Event, leakSensor: LeakSensor): Event {
  const { foundSensitiveData, foundSensitiveLabels } = leakSensor.check(event.extra);

  if (foundSensitiveData.length || foundSensitiveLabels.length) {
    event.extra = {
      foundSensitiveData: {
        foundSensitiveLabels,
        foundSensitiveDataValues: foundSensitiveData.length > 0,
      },
      original: {
        BLCode: event.tags && event.tags['BLCode'],
        message: event.message,
        level: event.level,
      },
    };

    if (event.tags) event.tags['BLCode'] = LeakSensorBLCode.FrontendSensitiveLeakDetected;
    event.message = LEAK_SENSOR_DETECTED_MESSAGE;
    event.level = 'error';
  }

  return event;
}
