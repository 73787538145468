import { ReactElement } from 'react';

import config from '@breathelife/config/frontend';
import DynamicPdf from '@breathelife/dynamic-pdf';
import { IsoCountryCode, Language, PdfOptions, SignatureFieldType } from '@breathelife/types';

function DynamicPdfContainer(): ReactElement {
  const pdfTextOverrides = config.get<Partial<Record<Language, any>>>('localization.pdf');

  return (
    <DynamicPdf
      carrierPdfOptions={config.get<PdfOptions>('features.pdfGeneration.carrierPdfOptions')}
      insuredPdfOptions={config.get<PdfOptions>('features.pdfGeneration.insuredPdfOptions')}
      advisorPdfOptions={config.get<PdfOptions>('features.pdfGeneration.advisorPdfOptions')}
      countryCode={config.get<IsoCountryCode>('carrier.defaultCountryCode')}
      pdfTextOverrides={pdfTextOverrides}
      signatureOptions={{
        eSignatureFieldType: config.get<SignatureFieldType>('features.eSignature.signatureFieldType'),
      }}
    />
  );
}

export default DynamicPdfContainer;
