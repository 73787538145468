import { failure, Result, success } from './result';
import * as z from 'zod';

export class ParsingError extends Error {
  readonly tag = 'ParsingError';
}

export type Parser<T> = (value: unknown) => Result<ParsingError, T>;

export function getParser<TOutput extends z.ZodType>(schema: TOutput): Parser<z.TypeOf<TOutput>> {
  return (value: unknown) => {
    const result = schema.safeParse(value);

    if (!result.success) {
      return failure(new ParsingError(result.error.message, { cause: result.error }));
    }

    return success(result.data);
  };
}
