import { ReactElement, useMemo } from 'react';

import config from '@breathelife/config/frontend';
import featureFlag, { FeatureFlag } from '@breathelife/feature-flag';
import { EnabledTabs, LeadPlatform, LeadStatusesColumnData } from '@breathelife/lead-platform';
import {
  Auth0Config,
  CloudinaryConfig,
  ConsumerFeedbackProvider,
  CountryCode,
  DelightedSurveysConfig,
  EmailNotificationTypes,
  EnforceUniqueEmailStrategy,
  Language,
  LaunchApplicationType,
  LeadPlatformHeaderVariants,
  LeadStatus,
  LeadTableColumnInfo,
  LineOfBusinessName,
  NodeIdAgentLicenseConfig,
  PaymentServiceProvider,
  ProductsWidgetFeatureType,
  QuestionnaireScreensConfig,
  SignatureType,
  ESignSignerAuthMethod,
  NodeId,
} from '@breathelife/types';

const isEmailActionEnabled = (leadStatus: LeadStatus): boolean => {
  const enabledStatuses: LeadStatus[] = config.get<LeadStatus[]>('features.leadsPlatform.emails.enabledStatuses');
  return enabledStatuses.includes(leadStatus);
};

function LeadPlatformContainer(): ReactElement {
  const leadPlatformTextOverrides = config.get<Partial<Record<Language, any>>>('localization.leadPlatform');
  const tableColumns = useMemo(() => {
    const columns = config.get<boolean>('featureFlags.enableHiddenLeads')
      ? config.get<LeadTableColumnInfo[]>('features.leadsPlatform.table.columns.applications')
      : config.get<LeadTableColumnInfo[]>('features.leadsPlatform.table.columns.leads');

    if (config.get<boolean>('features.questionnaireScreen.enabled')) {
      columns.push(config.get<LeadTableColumnInfo>('features.questionnaireScreen.tableColumn'));
    }
    return columns;
  }, []);
  const fileUploadsAllowedLinesOfBusiness = config.get<LineOfBusinessName[]>(
    'features.fileUpload.allowedLinesOfBusiness',
  );

  const enabledTwoFactorAuthenticationTypes = config.get<ESignSignerAuthMethod[]>(
    'features.eSignature.enabledTwoFactorAuthenticationTypes',
  );

  return (
    <LeadPlatform
      carrierInfo={{
        companyName: config.get<string>('carrier.companyName.en'),
        id: config.get<string>('carrier.id'),
        // TODO HOT-4574: Remove lineOfBusiness from config
        lineOfBusiness: config.get<LineOfBusinessName[]>('carrier.lineOfBusiness'),
        validFileUploadMimeTypes: config.get('carrier.validFileUploadMimeTypes'),
      }}
      config={{
        auth0: config.get<Auth0Config>('keys.auth0'),
        cloudinary: config.get<CloudinaryConfig>('keys.cloudinary'),
        countryCode: config.get<CountryCode>('carrier.defaultCountryCode'),
        git: {
          commitSha: config.get<string>('git.commitSha'),
        },
        googleMapsPlaces: config.get<string>('keys.googleMapsPlaces'),
      }}
      enabledTabs={
        config.get<boolean>('featureFlags.enableHiddenLeads')
          ? config.get<EnabledTabs>('features.leadsPlatform.drawer.tabs.applications')
          : config.get<EnabledTabs>('features.leadsPlatform.drawer.tabs.leads')
      }
      isEmailActionEnabled={isEmailActionEnabled}
      headerVariant={config.get<LeadPlatformHeaderVariants>('carrier.headerVariant')}
      leadStatuses={config.get<LeadStatusesColumnData>('features.leadsPlatform.leads.statuses')}
      leadTableColumns={tableColumns}
      mgas={[]}
      monitoringEnvironment={config.get<string>('monitoring.environment')}
      textOverrides={leadPlatformTextOverrides}
      features={{
        agentLicenseValidation: {
          enabled: config.get<boolean>('featureFlags.enableAgentLicenseValidation'),
        },
        applicationCopy: {
          disabledForLineOfBusiness: config.get<LineOfBusinessName[] | undefined>(
            'features.applicationCopy.disabledForLineOfBusiness',
          ),
          applicationCopyThresholdInDays: config.get<number>('features.applicationCopy.applicationCopyThresholdInDays'),
          enabled: config.get<boolean>('features.applicationCopy.enabled'),
          startDate: config.get<string>('features.applicationCopy.startDate'),
        },
        applicationMarketingDataExtract: {
          enabled: config.get<boolean>('featureFlags.enableApplicationMarketingDataExtract'),
        },
        applicationSupportAssociatedFiles: {
          enabled: config.get<boolean>('featureFlags.enableApplicationSupportAssociatedFiles'),
        },
        applicationSupportTools: {
          enabled: featureFlag.get(FeatureFlag.enableApplicationSupportTools),
        },
        assistedApplication: {
          adoMinMax: {
            enabled: config.get<boolean>('features.adoMinMax.enabled'),
            adoOptionNodeId: config.get<string>('features.nodeIds.ado.option'),
            adoCoverageAmount: config.get<string>('features.nodeIds.ado.coverageAmount'),
          },
          documentsDrawer: {
            enabled: config.get<boolean>('featureFlags.enableDocumentsDrawer'),
          },
          equivalentSingleAge: {
            enabled: config.get<boolean>('features.equivalentSingleAge.enabled'),
            jointProducts: config.get<string[]>('features.equivalentSingleAge.jointProducts'),
            selectedProductNodeId: config.get<string>('features.nodeIds.equivalentSingleAge.selectedProduct'),
            insuredInfoNodeIds: config.get<string[]>('features.nodeIds.equivalentSingleAge.insuredInfo'),
          },
          jetDecisionWidget: {
            enabled: config.get<boolean>('features.jetDecisionWidget.enabled'),
          },
          nodeIdAgentLicenseConfig: config.get<NodeIdAgentLicenseConfig>('features.nodeIds.agentLicense'),
          nodeIdsAffectingPricing: config.get('features.nodeIds.pricing') as NodeId[],
          nodeIdsAffectingProducts: config.get('features.nodeIds.product') as NodeId[],
          nodeIdsApplicantFullNamePerLineOfBusiness: {
            [LineOfBusinessName.life]: {
              firstName: config.get<NodeId>(`features.nodeIds.applicantFullName.${LineOfBusinessName.life}.firstName`),
              lastName: config.get<NodeId>(`features.nodeIds.applicantFullName.${LineOfBusinessName.life}.lastName`),
            },
            [LineOfBusinessName.legacyLife]: {
              firstName: config.get<NodeId>(`features.nodeIds.applicantFullName.${LineOfBusinessName.life}.firstName`),
              lastName: config.get<NodeId>(`features.nodeIds.applicantFullName.${LineOfBusinessName.life}.lastName`),
            },
            [LineOfBusinessName.annuity]: {
              firstName: config.get<NodeId>(
                `features.nodeIds.applicantFullName.${LineOfBusinessName.annuity}.firstName`,
              ),
              lastName: config.get<NodeId>(`features.nodeIds.applicantFullName.${LineOfBusinessName.annuity}.lastName`),
            },
            [LineOfBusinessName.legacyAnnuity]: {
              firstName: config.get<NodeId>(
                `features.nodeIds.applicantFullName.${LineOfBusinessName.annuity}.firstName`,
              ),
              lastName: config.get<NodeId>(`features.nodeIds.applicantFullName.${LineOfBusinessName.annuity}.lastName`),
            },
            [LineOfBusinessName.incomeProtection]: {
              firstName: config.get<NodeId>(
                `features.nodeIds.applicantFullName.${LineOfBusinessName.incomeProtection}.firstName`,
              ),
              lastName: config.get<NodeId>(
                `features.nodeIds.applicantFullName.${LineOfBusinessName.incomeProtection}.lastName`,
              ),
            },
            [LineOfBusinessName.accidentIllness]: {
              firstName: config.get<NodeId>(
                `features.nodeIds.applicantFullName.${LineOfBusinessName.accidentIllness}.firstName`,
              ),
              lastName: config.get<NodeId>(
                `features.nodeIds.applicantFullName.${LineOfBusinessName.accidentIllness}.lastName`,
              ),
            },
          },
          nodeIdsSkippingDebounce: config.get<boolean>('features.applicationCopy.enabled')
            ? (config.get('features.nodeIds.noDebounce') as NodeId[])
            : [],
          productsWidget: {
            enabled: config.get<boolean>('features.productsWidget.enabled'),
            allowedLinesOfBusiness: config.get<LineOfBusinessName[]>('features.productsWidget.allowedLinesOfBusiness'),
            type: config.get<ProductsWidgetFeatureType>('features.productsWidget.type'),
          },
          ...(fileUploadsAllowedLinesOfBusiness && {
            fileUpload: {
              allowedLinesOfBusiness: fileUploadsAllowedLinesOfBusiness,
            },
          }),
        },
        auditLogs: {
          enabled: config.get<boolean>('featureFlags.enableAuditLogs'),
        },
        consumerFeedback: {
          enabled: config.get<boolean>('features.consumerFeedback.enabled'),
          provider: config.get<ConsumerFeedbackProvider>('features.consumerFeedback.provider'),
          delighted: config.get<DelightedSurveysConfig>('features.consumerFeedback.delighted'),
        },
        documentTemplate: {
          enabled: config.get<boolean>('featureFlags.enableDocumentTemplateFeature'),
        },
        enableInstantIdRequest: {
          enabled: config.get<boolean>('featureFlags.enableInstantIdRequest'),
        },
        eSignature: {
          enabledTwoFactorAuthenticationTypes,
          enforceUniqueEmail: config.get<EnforceUniqueEmailStrategy>('features.eSignature.enforceUniqueEmail'),
        },
        hiddenLeads: {
          enabled: config.get<boolean>('featureFlags.enableHiddenLeads'),
        },
        importCopyDeck: {
          enabled: config.get<boolean>('featureFlags.enableImportFromCopyDeck'),
        },
        launchApplication: {
          enabled: config.get<boolean>('features.launchApplication.enabled'),
          signatureType: config.get<SignatureType>('features.signatureType.leadPlatform'),
          type: config.get<LaunchApplicationType>('features.launchApplication.type'),
        },
        leadCreation: {
          enabled: config.get<boolean>('featureFlags.enableLeadCreation'),
        },
        loadCarrierNamesFromDb: {
          enabled: config.get<boolean>('featureFlags.enableLoadCarrierNamesFromDb'),
        },
        loadProductInformationFromDb: {
          enabled: config.get<boolean>('featureFlags.enableLoadProductInformationFromDb'),
        },
        loadPublicLinkMetadataFromDb: {
          enabled: config.get<boolean>('featureFlags.enableLoadPublicLinkMetadataFromDb'),
        },
        multiBranding: {
          enabled: config.get<boolean>('features.multiBranding.enabled'),
        },
        multiQuestionnaire: {
          enabled: config.get<boolean>('features.multiQuestionnaire.enabled'),
        },
        needsAnalysis: {
          enabled: config.get<boolean>('features.needsAnalysis.enabled'),
          optional: config.get<boolean>('featureFlags.enableOptionalNeedsAnalysis'),
        },
        notificationCentre: {
          enabled: config.get<boolean>('features.notificationCentre.enabled'),
          maxDays: config.get<number>('features.notificationCentre.maxDays'),
          cloudinaryImages: config.get<{
            informational: { en: string; fr: string };
            actionable: { en: string; fr: string };
          }>('features.notificationCentre.cloudinaryImages'),
        },
        payments: {
          enabled: config.get<boolean>('features.payments.enabled'),
          serviceProvider: config.get<PaymentServiceProvider>('features.payments.serviceProvider'),
          [config.get<PaymentServiceProvider>('features.payments.serviceProvider')]: config.get(
            `features.payments.${config.get<PaymentServiceProvider>('features.payments.serviceProvider')}`,
          ),
        },
        pointOfSaleDecisionOutcome: {
          enabled: config.get<boolean>('features.pointOfSaleDecisionOutcome.enabled'),
        },
        pricingCalculation: {
          enabled: config.get<boolean>('featureFlags.enableLoadingPricingNodeIdsFromDB'), // TODO HOT-4320: Replace it with feature once this is tested and released
        },
        productHub: {
          enabled: config.get<boolean>('features.productHub.enabled'),
        },
        questionnaireBuilder: {
          enabled: config.get<boolean>('featureFlags.enableQuestionnaireBuilder'),
        },
        questionnaireDebugTools: {
          enabled: config.get<boolean>('debug.displayQuestionnaireFillerToolbar'),
        },
        questionnaireEditor: {
          enabled: config.get<boolean>('featureFlags.enableQuestionnaireEditor'),
        },
        questionnairePreview: {
          enabled: config.get<boolean>('featureFlags.enableQuestionnairePreview'),
        },
        questionnaireSchemaExport: {
          enabled: config.get<boolean>('featureFlags.enableQuestionnaireSchemaExport'),
        },
        questionnaireScreen: {
          enabled: config.get<boolean>('features.questionnaireScreen.enabled'),
          config: config.get<QuestionnaireScreensConfig>('features.questionnaireScreen.config'),
        },
        requireSalesDecisionRuleIdentifier: {
          enabled: config.get<boolean>('features.requireSalesDecisionRuleIdentifier.enabled'),
        },
        downloadSubmissionFiles: {
          enabled: featureFlag.get(FeatureFlag.enableDownloadSubmissionFiles),
        },
        salesDecisionRules: {
          enabled: config.get<boolean>('featureFlags.enableSalesDecisionRules'),
        },
        showBlueprintIdInEditor: {
          enabled: config.get<boolean>('features.engine.showBlueprintIdInEditor'),
        },
        signatureType: config.get<SignatureType>('features.signatureType.leadPlatform'),
        softwareByWidget: {
          enabled: config.get<boolean>('features.softwareByWidget.enabled'),
        },
        thirdPartyIntegrations: {
          enabled: config.get<boolean>('featureFlags.enableThirdPartyIntegrations'),
        },
        userNotificationEmail: {
          enabled: config.get<boolean>('features.userNotificationEmail.enabled'),
          emailTypes: config.get<EmailNotificationTypes[]>('features.userNotificationEmail.emailTypes'),
        },
        userSecurityDisclosure: {
          enabled: config.get<boolean>('featureFlags.enableUserSecurityDisclosure'),
        },
      }}
    />
  );
}

export default LeadPlatformContainer;
