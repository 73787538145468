import { Metric, MonitoringConfig } from '@breathelife/types';

import { buildLogEntry } from './helpers';
import {
  initDatadog,
  setDatadogUser,
  setDatadogProduct,
  logDatadogError,
  logDatadogWarning,
  setDatadogTraceId,
} from './datadog-transport';
import {
  initSentry,
  setSentryUser,
  setSentryProduct,
  logSentryError,
  setSentryTraceId,
  logSentryWarning,
  sendMeasurement,
} from './sentry-transport';
import { MonitoredUser, ProductName, LogEntry, Logger } from './types';
import { knownSensitiveData, LeakSensor, sensitiveDataLabels } from '@breathelife/monitoring-common';

export { ProductName } from './types/productName';

const traceId = Math.random().toString(36).substr(2, 9);
let sentryEnabled = false;
let datadogEnabled = false;

export function initMonitoring(
  carrierId: string,
  config: MonitoringConfig,
  getIsLeakSensorEnabled: () => boolean,
): void {
  // TODO using process.env isn't ideal in a shared package - We should take this from the arguments
  const version = `frontend#${carrierId}#${process.env.GIT_SHA}`;

  const leakSensor = new LeakSensor(
    config.environment === 'prod' ? undefined : knownSensitiveData,
    sensitiveDataLabels,
  );

  if (config.sentry.enabled) {
    sentryEnabled = true;
    initSentry(
      config.sentry,
      config.sentryEventFilters,
      carrierId,
      config.environment,
      version,
      leakSensor,
      getIsLeakSensorEnabled,
    );
    setSentryTraceId(traceId);
  }
  if (config.datadog.enabled) {
    datadogEnabled = true;
    initDatadog(config.datadog, carrierId, config.environment, version);
    setDatadogTraceId(traceId);
  }
}

export function setMonitoringUser(user: MonitoredUser): void {
  if (sentryEnabled) {
    setSentryUser(user);
  }
  if (datadogEnabled) {
    setDatadogUser(user);
  }
}

export function setMonitoringProduct(productName: ProductName): void {
  if (sentryEnabled) {
    setSentryProduct(productName);
  }
  if (datadogEnabled) {
    setDatadogProduct(productName);
  }
}

// TODO: Remove after HOT-1822
export function getTraceId(): string {
  return traceId;
}

export const logger: Logger = {
  error: (error: LogEntry | Error | string | any) => {
    const logEntry = buildLogEntry(error);
    if (sentryEnabled) {
      logSentryError(logEntry);
    }
    if (datadogEnabled) {
      logDatadogError(logEntry);
    }
  },
  warn: (error: LogEntry | Error | string | any) => {
    const logEntry = buildLogEntry(error);
    if (sentryEnabled) {
      logSentryWarning(logEntry);
    }
    if (datadogEnabled) {
      logDatadogWarning(logEntry);
    }
  },
  keep: (metric: Metric) => {
    if (sentryEnabled) {
      sendMeasurement(metric);
    }
  },
};
