export enum DataLabel {
  Unknown = 'Unknown',
  KnownSafe = 'KnownSafe',
  PII = 'PII',
  PHI = 'PHI',
  IdentifiablePHI = 'IdentifiablePHI',
  RecordId = 'RecordId',
  RecordUUID = 'RecordUUID',
  ReferenceNumber = 'ReferenceNumber',
  ExternalStringId = 'ExternalStringId',
  PostalCode = 'PostalCode',
  DateOfBirth = 'DateOfBirth',
  IdentifiableDate = 'IdentifiableDate',
  NonIdentifiableDate = 'NonIdentifiableDate',
  FreeFormText = 'FreeFormText',
  AgeAtServiceTime = 'AgeAtServiceTime',
  Language = 'Language',
  LiveAnswers = 'LiveAnswers', // null from the source db, decrypted and anonymized by custom transformations in the target db
  LiveEncryptedAnswers = 'LiveEncryptedAnswers',
  ArchivedAnswers = 'ArchivedAnswers',
  ArchivedEncryptedAnswers = 'ArchivedEncryptedAnswers',
  SensitiveSystemData = 'SensitiveSystemData',
  AdvisorEmail = 'AdvisorEmail',
  LoginEmail = 'LoginEmail',
  InsuredPersonEmail = 'InsuredPersonEmail',
  LeadEmail = 'LeadEmail',
  Email = 'Email',
  SystemTimestamp = 'SystemTimestamp',
  EncryptedData = 'EncryptedData',
}

export type DataDescriptor = DataDescriptorObject | DataDescriptor[];
export type DataDescriptorObject = { [key: string]: DataDescriptor | DataLabel | CompoundLabel };

export class CompoundLabel {
  public readonly labels: DataLabel[];

  constructor(labels: DataLabel[]) {
    this.labels = labels;
  }
}

export function isCompoundLabel(label: DataLabel | CompoundLabel): label is CompoundLabel {
  return label instanceof CompoundLabel;
}

export function combineLabels(...dataLabels: (DataLabel | CompoundLabel)[]): CompoundLabel {
  const labels: DataLabel[] = [];

  for (const dataLabel of dataLabels) {
    if (isCompoundLabel(dataLabel)) {
      labels.push(...dataLabel.labels);
    } else {
      labels.push(dataLabel);
    }
  }
  return new CompoundLabel(labels);
}
