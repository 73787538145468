import { z } from 'zod';
import { ParsingError } from './parser';

export function formatZodErrors(parsingError: ParsingError) {
  if (parsingError.cause instanceof z.ZodError) {
    return parsingError.cause.issues.map((issue) => {
      const path = issue.path.join('.');
      return `${issue.message} at path ${path}`;
    });
  }
  return `Unable to parse zod error: ${parsingError.message}`;
}
