/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
interface IResult<A, B> {
  andThen<C, D>(f: (b: B) => Result<C, D>): Result<A | C, D>;

  map<C>(f: (b: B) => C): Result<A, C>;

  mapError<C>(f: (a: A) => C): Result<C, B>;

  orElse<C>(f: (a: A) => Result<C, B>): Result<C, B>;

  withDefault<C>(fallback: C): B | C;

  unsafe: () => B;
}

export type Result<A, B> = Success<A, B> | Failure<A, B>;

export type AsyncResult<F, S> = Promise<Result<F, S>>;

export class Success<A, B> implements IResult<A, B> {
  public readonly success = true;
  public readonly value: B;

  constructor(value: B) {
    this.value = value;
  }

  map<C>(f: (b: B) => C): Result<A, C> {
    return success(f(this.value));
  }

  mapError<C>(_f: (a: A) => C): Result<C, B> {
    return success(this.value);
  }

  andThen<C, D>(f: (a: B) => Result<C, D>): Result<A | C, D> {
    return f(this.value);
  }

  orElse<C>(_f: (a: A) => Result<C, B>): Result<C, B> {
    return success(this.value);
  }

  withDefault<C>(_fallback: C): B | C {
    return this.value;
  }

  unsafe(): B {
    return this.value;
  }
}

export class Failure<A, B> implements IResult<A, B> {
  public readonly success = false;
  public readonly error: A;

  constructor(error: A) {
    this.error = error;
  }

  map<C>(_f: (b: B) => C): Result<A, C> {
    return failure(this.error);
  }

  mapError<C>(f: (b: A) => C): Result<C, B> {
    return failure(f(this.error));
  }

  andThen<C, D>(f: (a: B) => Result<C, D>): Result<A | C, D> {
    return failure(this.error);
  }

  orElse<C>(f: (a: A) => Result<C, B>): Result<C, B> {
    return f(this.error);
  }

  withDefault<C>(fallback: C): B | C {
    return fallback;
  }

  unsafe(): B {
    throw new Error(`Could not unwrap the result because it is a failure.`);
  }
}

export function success<B>(value: B): Result<never, B> {
  return new Success(value);
}

export function failure<A>(error: A): Result<A, never> {
  return new Failure(error);
}

export const isSuccess = <A, B>(result: Result<A, B>): result is Success<A, B> => result.success === true;
export const isFailure = <A, B>(result: Result<A, B>): result is Failure<A, B> => result.success === false;

export const map = <TArgs extends unknown[], F>(fn: (...args: TArgs) => F) => {
  return <
    R extends {
      [Index in keyof TArgs]: Result<unknown, TArgs[Index]>;
    },
  >(
    ...arr: R
  ): R extends Result<infer B, unknown>[] ? Result<B, F> : never => {
    if (arr.every(isSuccess)) {
      const resolvedArgs = arr.map((e) => (e as Success<unknown, unknown>).value) as TArgs;
      return success(fn(...resolvedArgs)) as any;
    }

    for (const result of arr) {
      if (result.success === false) {
        return failure(result.error) as any;
      }
    }

    throw new Error('Result.map did not find any success or error. This is not supposed to happen.');
  };
};

// TODO: Missing unit tests
export const andThen = <TArgs extends unknown[], E, F>(fn: (...args: TArgs) => Result<E, F>) => {
  return <
    R extends {
      [Index in keyof TArgs]: Result<unknown, TArgs[Index]>;
    },
  >(
    ...arr: R
  ): R extends Result<infer B, unknown>[] ? Result<B | E, F> : never => {
    if (arr.every(isSuccess)) {
      const resolvedArgs = arr.map((e) => (e as Success<unknown, unknown>).value) as TArgs;
      return success(fn(...resolvedArgs)) as any;
    }

    for (const result of arr) {
      if (result.success === false) {
        return failure(result.error) as any;
      }
    }

    throw new Error('Result.map did not find any success or error. This is not supposed to happen.');
  };
};
