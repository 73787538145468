import { ModuleFilterConfig } from '@breathelife/types';
import { Event } from '@sentry/browser';
import { EventModifierReturnType } from '../types';
import { toSeverityLevel } from '../utils';

export function extractModulesFromEvent(event: Event): string[] {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames ?? [];
  const modules = frames.map((frame) => frame.module).filter((module): module is string => typeof module === 'string');
  return modules;
}

export function moduleFilter(event: Event, moduleFilterConfig: ModuleFilterConfig[]): EventModifierReturnType {
  const eventModules = extractModulesFromEvent(event);

  for (const eventModule of eventModules) {
    for (const { module, fingerprint, severity } of moduleFilterConfig) {
      const modifyEvent = eventModule.includes(module);

      if (modifyEvent) {
        return { fingerprint, severity: toSeverityLevel(severity) };
      }
    }
  }
}
