import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ErrorState = {
  errorId: string | null;
};

const defaultState = {
  errorId: null,
};

type ErrorPayload = {
  errorId: string | null;
};

type ActionsType = {
  setError: (state: ErrorState, { payload }: PayloadAction<ErrorPayload>) => void;
};

export const errorSlice = createSlice<ErrorState, ActionsType>({
  name: 'error',
  initialState: defaultState,
  reducers: {
    setError(state: ErrorState, { payload }: PayloadAction<ErrorPayload>) {
      state.errorId = payload.errorId;
    },
  },
});
