import { LogEntry } from '../types';

export function buildLogEntry(error: LogEntry | Error | string | any): LogEntry {
  if (typeof error === 'string') {
    return {
      message: error,
    };
  } else if (error instanceof Error) {
    return {
      error,
    };
  } else if (error.error || error.message) {
    return error as LogEntry;
  } else {
    const message = 'Logger error: Unknown error';
    return {
      message,
      details: error,
      error: new Error(message), // For the stack
    };
  }
}
