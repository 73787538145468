import { Event, EventHint } from '@sentry/types';
import { AxiosError } from 'axios';

export function formatAxiosErrorBeforeSend(event: Event, hint?: EventHint): Event {
  const originalException = hint?.originalException;
  if (originalException && isAxiosError(originalException)) {
    if (originalException.response && originalException.response.data) {
      const contexts = {
        ...event.contexts,
      };
      contexts.errorResponse = {
        data: originalException.response.data,
      };
      event.contexts = contexts;
    }

    if (originalException?.message.includes('Request failed with status code')) {
      const statusOrCode = originalException.response?.status || originalException.code;
      event.fingerprint = [
        [event.tags?.layer, event.tags?.domain, 'axios', 'error', statusOrCode].filter(Boolean).join(':'),
      ];
    }
  }

  return event;
}

function isAxiosError(exception: any): exception is AxiosError {
  return !!exception.isAxiosError || exception.name === 'AxiosError';
}
