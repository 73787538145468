import crypto from 'crypto';
import jwtDecode from 'jwt-decode';

export function generateNonce(): string {
  return crypto.randomBytes(16).toString('hex');
}

export function isTokenNonceValid(token: string, nonce: string): boolean {
  try {
    if (!nonce) return false;
    const decodedToken: { nonce: string } = jwtDecode(token);
    return decodedToken.nonce === nonce;
  } catch (e: any) {
    return false;
  }
}
