import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TokenPayload = {
  token?: string;
};

type NoncePayload = {
  nonce?: string;
};

export type AuthenticationState = TokenPayload & NoncePayload;

type ActionsType = {
  setNonce: (state: AuthenticationState, { payload }: PayloadAction<NoncePayload>) => void;
  setToken: (state: AuthenticationState, { payload }: PayloadAction<TokenPayload>) => void;
  reset: (state: AuthenticationState) => void;
};

const defaultState = {};

export const authenticationSlice = createSlice<AuthenticationState, ActionsType>({
  name: 'application/authentication',
  initialState: defaultState,
  reducers: {
    setNonce: (state: AuthenticationState, { payload }: PayloadAction<NoncePayload>) => {
      state.nonce = payload.nonce;
    },
    setToken: (state: AuthenticationState, { payload }: PayloadAction<TokenPayload>) => {
      state.token = payload.token;
    },
    reset: (state: AuthenticationState) => {
      state.token = undefined;
      state.nonce = undefined;
    },
  },
});
