import { Dispatch } from 'redux';

import { navigationSlice } from '../Slices/NavigationSlice';

export type A11yMessageProps = {
  setA11yMessage: (message: string) => void;
};

export const setA11yMessage = (message: string) => async (dispatch: Dispatch) => {
  dispatch(navigationSlice.actions.setA11yMessage({ a11yMessage: message }));
};
