import {
  captureException,
  captureMessage,
  configureScope,
  init,
  Scope,
  SeverityLevel,
  Integrations as BrowserIntegrations,
  getCurrentHub,
} from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { Metric, SentryConfig, SentryEventFiltersConfig } from '@breathelife/types';
import { LeakSensor } from '@breathelife/monitoring-common';

import { createBeforeSend } from './filters';
import { LogEntry, MonitoredUser, ProductName } from '../types';

export function initSentry(
  sentryConfig: SentryConfig,
  sentryEventFiltersConfig: SentryEventFiltersConfig,
  carrierId: string,
  environment: string,
  version: string,
  leakSensor: LeakSensor,
  getIsLeakSensorEnabled: () => boolean,
): void {
  init({
    beforeSend: createBeforeSend(sentryEventFiltersConfig, leakSensor, getIsLeakSensorEnabled),
    dsn: sentryConfig.dsn,
    environment: environment,
    release: version,
    integrations: [
      new BrowserIntegrations.Breadcrumbs({ console: false }),
      new TracingIntegrations.BrowserTracing({
        beforeNavigate: (context) => {
          return {
            ...context,
            // TODO: leverage react-router to have the right url path
            name: location.pathname
              .replace(/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g, '<UUID>')
              .replace(/\d+/g, '<DIGITS>'),
          };
        },
      }),
    ],
    // TODO: Add custom sampling once we have a better idea of how to optimize tracing: https://docs.sentry.io/platforms/javascript/performance/#verify
    tracesSampleRate: 1.0,
  });

  configureScope((scope: Scope) => {
    const tags: { [key: string]: string } = {
      layer: 'frontend',
      carrier: carrierId || '',
    };
    scope.setTags(tags);
  });
}

export function setSentryUser(user: MonitoredUser): void {
  configureScope((scope: Scope) => {
    scope.setUser({
      id: user.id,
      email: user.email,
      roles: user.roles,
    });
  });
}

export function setSentryProduct(productName: ProductName): void {
  configureScope((scope: Scope) => {
    scope.setTag('product', productName);
  });
}

export function setSentryTraceId(traceId: string): void {
  configureScope((scope: Scope) => {
    scope.setTag('traceId', traceId);
  });
}

export function logSentryError(logEntry: LogEntry): void {
  log(logEntry, 'error');
}

export function logSentryWarning(logEntry: LogEntry): void {
  log(logEntry, 'warning');
}

export function sendMeasurement(metric: Metric): void {
  if (metric.tag === 'TimeMeasurement') {
    const transaction = getCurrentHub()?.getScope()?.getTransaction();
    if (transaction) {
      transaction.setMeasurement(metric.name, metric.value, metric.unit);
    }
  }
}

function log(logEntry: LogEntry, level: SeverityLevel): void {
  const context = {
    tags: {
      BLCode: logEntry.BLCode,
    },
    level,
    extra: logEntry.details,
  };
  if (logEntry.error) {
    captureException(logEntry.error, context);
  } else if (logEntry.message) {
    captureMessage(logEntry.message, context);
  }
}
