import { configureStore, Middleware } from '@reduxjs/toolkit';
import _ from 'lodash';
import { combineReducers, Reducer, ReducersMapObject } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

import { ConsumerFlowStore, createTrackerMiddleware, Urls } from '@breathelife/consumer-flow';
import { leadPlatformStore, LeadPlatformStore } from '@breathelife/lead-platform';

import StorePersistenceConfig from './StorePersistenceConfig';

export type Store = ConsumerFlowStore & LeadPlatformStore;

const createRootReducer = (rootReducer: ReducersMapObject): Reducer =>
  combineReducers({
    ...rootReducer,
    ...leadPlatformStore.RootReducer,
  });

const createPersistedReducer = (rootReducer: ReducersMapObject): Reducer =>
  persistReducer(StorePersistenceConfig, createRootReducer(rootReducer));

const isTrackedPage = (path: string): boolean => {
  const nonFlowPaths = [Urls.default.home.fullPath, Urls.default.fourOhFour.fullPath, Urls.default.error.fullPath];
  return nonFlowPaths.includes(path);
};

const createRootMiddlewares = (): Middleware[] => [createTrackerMiddleware(isTrackedPage)];

export default function initializeReduxStore(rootReducer: ReducersMapObject, middlewares: Middleware[]) {
  const store = configureStore({
    reducer: createPersistedReducer(rootReducer),
    middleware: [...createRootMiddlewares(), ...middlewares],
  });

  const persistor = persistStore(store);

  return { store, persistor };
}
