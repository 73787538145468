export enum FeatureFlag {
  enableDirectSecureFlow = 'enableDirectSecureFlow',
  displayReferenceNumber = 'displayReferenceNumber',
  enableInstantIdRequest = 'enableInstantIdRequest',
  enableHeaderProductSummary = 'enableHeaderProductSummary',
  enableLoadCarrierNamesFromDb = 'enableLoadCarrierNamesFromDb',
  enableLoadPublicLinkMetadataFromDb = 'enableLoadPublicLinkMetadataFromDb',
  enableLoadProductInformationFromDb = 'enableLoadProductInformationFromDb',
  enableLegacyProductsImplementation = 'enableLegacyProductsImplementation',
  enableSalesDecisionRules = 'enableSalesDecisionRules',
  enableMultitenantLogin = 'enableMultitenantLogin',
  enableAuditLogs = 'enableAuditLogs',
  enableAcordXMLValidation = 'enableAcordXMLValidation',
  enableBilingualAdvisorInvitationEmail = 'enableBilingualAdvisorInvitationEmail',
  enableQuestionnaireEditor = 'enableQuestionnaireEditor',
  enableQuestionnairePreview = 'enableQuestionnairePreview',
  enableQuestionnaireBuilder = 'enableQuestionnaireBuilder',
  enableImportFromCopyDeck = 'enableImportFromCopyDeck',
  enableThirdPartyIntegrations = 'enableThirdPartyIntegrations',
  enableOptionalNeedsAnalysis = 'enableOptionalNeedsAnalysis',
  isUsingLegacyAuthenticationSystem = 'isUsingLegacyAuthenticationSystem',
  enableUserSecurityDisclosure = 'enableUserSecurityDisclosure',
  enableDocumentsDrawer = 'enableDocumentsDrawer',
  enableDocumentTemplateFeature = 'enableDocumentTemplateFeature',
  enableLeadOwnerSync = 'enableLeadOwnerSync',
  enableHiddenLeads = 'enableHiddenLeads',
  enableQuestionnaireVersionImportValidation = 'enableQuestionnaireVersionImportValidation',
  enableLeadCreation = 'enableLeadCreation',
  enableProductSelection = 'enableProductSelection',
  enableHidingRequestDataFromUnderwritingOutcomeReport = 'enableHidingRequestDataFromUnderwritingOutcomeReport',
  enableApplicationMarketingDataExtract = 'enableApplicationMarketingDataExtract',
  enableDecoupleESignFlow = 'enableDecoupleESignFlow',
  enableAgentLicenseValidation = 'enableAgentLicenseValidation',
  enableApplicationSupportTools = 'enableApplicationSupportTools',
  enableApplicationSupportAssociatedFiles = 'enableApplicationSupportAssociatedFiles',
  enableSalesDecisionRulesDetailsInSubmission = 'enableSalesDecisionRulesDetailsInSubmission',
  enableQuestionnaireBlueprintUpdateValidation = 'enableQuestionnaireBlueprintUpdateValidation',
  enableCloverPayment = 'enableCloverPayment',
  enableDownloadSubmissionFiles = 'enableDownloadSubmissionFiles',
  enableLoggingLeakSensor = 'enableLoggingLeakSensor',
  enableQuestionnaireSchemaExport = 'enableQuestionnaireSchemaExport',
  enableResonantSubmission = 'enableResonantSubmission',
  enableCloverForwardedForClientIp = 'enableCloverForwardedForClientIp',
  enableSubmitOneSpanEvidenceSummaryToIngenium = 'enableSubmitOneSpanEvidenceSummaryToIngenium',
  enableLoadingPricingNodeIdsFromDB = 'enableLoadingPricingNodeIdsFromDB',
  enableMultipleMainProductsForAccidentIllness = 'enableMultipleMainProductsForAccidentIllness',
}

function isExistingFlag(name: string): name is FeatureFlag {
  return Object.keys(FeatureFlag).includes(name);
}

export type FeatureFlagConfig = Partial<Record<FeatureFlag, boolean>>;

let featureFlags: FeatureFlag[] = [];
let initialized = false;

function init(featureFlagConfig: FeatureFlagConfig = {}): FeatureFlag[] {
  featureFlags = pickActiveFeatureFlags(featureFlagConfig);
  initialized = true;
  return featureFlags;
}

function pickActiveFeatureFlags(featureFlagConfig: FeatureFlagConfig): FeatureFlag[] {
  const flagsInConfig = Object.keys(featureFlagConfig) as FeatureFlag[];

  return flagsInConfig.filter((flag) => featureFlagConfig[flag] === true);
}

function isInitialized(): boolean {
  return initialized;
}

function get(name: string): boolean {
  if (!initialized) {
    throw new Error('Feature flags not initialized. Please call the init function before getting a feature flag.');
  }

  if (isExistingFlag(name)) {
    return featureFlags.includes(name);
  }

  // log the error, but do not throw to not block
  // eslint-disable-next-line no-console
  console.error(`Unknown feature flag name used: ${name}`);
  return false;
}

export default {
  init,
  get,
  isInitialized,
};

export const featureFlagsConfigSchema = {
  type: 'object',
  properties: Object.keys(FeatureFlag).reduce(
    (schema, flag) => {
      schema[flag as FeatureFlag] = { type: 'boolean' };

      return schema;
    },
    {} as Record<FeatureFlag, { type: 'boolean' }>,
  ),
};
