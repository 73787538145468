import { datadogRum } from '@datadog/browser-rum';

import { DataDogConfig } from '@breathelife/types';

import { LogEntry, MonitoredUser, ProductName } from '../types';
import { beforeSend } from './filters';

export function initDatadog(
  datadogConfig: DataDogConfig,
  carrierId: string,
  environment: string,
  version: string,
): void {
  datadogRum.init({
    applicationId: datadogConfig.applicationId,
    clientToken: datadogConfig.clientToken,
    site: datadogConfig.site ?? 'datadoghq.com',
    service: datadogConfig.service,
    env: environment,
    version,
    sessionSampleRate: datadogConfig.sampleRate ?? 100,
    sessionReplaySampleRate: datadogConfig.replaySampleRate ?? 0,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: datadogConfig.trackUserInteractions ?? true,
    trackFrustrations: datadogConfig.trackFrustrations ?? true,
    defaultPrivacyLevel: 'mask',
    allowedTracingUrls: [document.location.origin],
    beforeSend: beforeSend,
  });
  datadogRum.setGlobalContextProperty('carrierId', carrierId);
  if (!datadogConfig.replaySampleRate) {
    datadogRum.startSessionReplayRecording();
  }
}

export function setDatadogUser(user: MonitoredUser): void {
  datadogRum.setUser({
    id: user.id,
    email: user.email,
    roles: user.roles,
  });
}

export function setDatadogProduct(productName: ProductName): void {
  datadogRum.setGlobalContextProperty('productName', productName);
}

export function setDatadogTraceId(traceId: string): void {
  datadogRum.setGlobalContextProperty('traceId', traceId);
}

export function logDatadogError(logEntry: LogEntry): void {
  datadogRum.addError(logEntry, { message: logEntry.message, ...logEntry.details });
}

export function logDatadogWarning(logEntry: LogEntry): void {
  // There is no concept of warning in Datadog's RUM so we will log as error and see the result in staging
  datadogRum.addError(logEntry, { message: logEntry.message, ...logEntry.details });
}
