import { PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { consumerFlowStore } from '@breathelife/consumer-flow';
import { leadPlatformStore } from '@breathelife/lead-platform';
import { autoMerge } from '@breathelife/redux';

const storePersistenceConfig: PersistConfig<any> = {
  storage,
  key: 'primary',
  version: 1,
  blacklist: ['localization', 'router'],
  stateReconciler: autoMerge(3),
  transforms: [...leadPlatformStore.persistenceTransforms, ...consumerFlowStore.persistenceTransforms],
};

export default storePersistenceConfig;
