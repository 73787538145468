import jszip from 'jszip';

import { FileData } from '@breathelife/types';

export async function generateZip(files: FileData[]): Promise<ArrayBuffer> {
  const zip = new jszip();

  files.forEach((file) => {
    if (file.fileName.endsWith('.json') || (file.fileName.endsWith('.xml') && file.data)) {
      zip.file(file.fileName, Buffer.from(file.data as string));
    } else {
      if (file.data) {
        const content = (file.data as any).data;
        zip.file(file.fileName, content ? Buffer.from(content) : file.data);
      }
    }
  });

  const buffer: Uint8Array[] = [];

  return new Promise((resolve, reject) => {
    zip
      .generateNodeStream({ streamFiles: true })
      .on('data', (chunk: Uint8Array) => buffer.push(chunk))
      .on('end', () => {
        resolve(Buffer.concat(buffer));
      })
      .on('error', (err) => {
        reject(err);
      });
  });
}

export function downloadZipFile(buffer: Buffer, fileName: string): void {
  const dataBlob = new Blob([buffer], { type: 'application/zip' });
  const dataBlobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(dataBlob)
      : window.webkitURL.createObjectURL(dataBlob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = dataBlobURL;
  tempLink.setAttribute('download', fileName);
  document.body.appendChild(tempLink);
  tempLink.click();
  tempLink.parentNode && tempLink.parentNode.removeChild(tempLink);
}
