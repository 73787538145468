export const knownSensitiveData = [
  // First name, Last name
  ['bumfuzzle'],
  // Email
  ['username+bumfuzzle@breathelife.com'],
  // Phone number
  ['564-8378'],
  ['5648378'],
  // Street number
  ['4386912749'],
  // Street address
  ['bumfuzzle'],
  // Apartment number
  ['4386912749'],
  // Transit code, Institution number, Account number
  ['78366'],
  ['6735869'],
  // Social security number
  ['078-05-1120'],
  ['078051120'],
  // Social identification number
  ['290-359-850'],
  ['290359850'],
];
