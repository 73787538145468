export enum LeakSensorBLCode {
  LoggingSensitiveLeakDetected = 'LoggingSensitiveLeakDetected',
  FrontendSensitiveLeakDetected = 'FrontendSensitiveLeakDetected',
}

export const LEAK_SENSOR_DETECTED_MESSAGE = 'Potential sensitive leak detected';

export type LeakResult = {
  foundSensitiveLabels: string[];
  foundSensitiveData: string[];
};
