//Assisted Application
export enum AssistedApplicationErrorIds {
  //Pricing
  MissingRequiredInputs = 'Pricing:MissingRequiredInputs',

  //Identity Verification
  IdentityVerificationInvalidRequestFirstNameEmpty = 'IdentityVerification:InvalidRequest:FirstName:Empty',
  IdentityVerificationInvalidRequestLastNameEmpty = 'IdentityVerification:InvalidRequest:LastName:Empty',
  IdentityVerificationInvalidRequestDOBYearMin = 'IdentityVerification:InvalidRequest:DOB:Year:Min',
  IdentityVerificationInvalidRequestDOBMonthMin = 'IdentityVerification:InvalidRequest:DOB:Month:Min',
  IdentityVerificationInvalidRequestDOBMonthMax = 'IdentityVerification:InvalidRequest:DOB:Month:Max',
  IdentityVerificationInvalidRequestDOBDayMin = 'IdentityVerification:InvalidRequest:DOB:Day:Min',
  IdentityVerificationInvalidRequestDOBDayMax = 'IdentityVerification:InvalidRequest:DOB:Day:Max',
  IdentityVerificationInvalidRequestTelephoneClassificationType = 'IdentityVerification:InvalidRequest:Telephone:Classification:Type',
  IdentityVerificationInvalidRequestTelephoneClassificationEmpty = 'IdentityVerification:InvalidRequest:Telephone:Classification:Empty',
  IdentityVerificationInvalidRequestTelephoneAreaCodePattern = 'IdentityVerification:InvalidRequest:Telephone:AreaCode:Pattern',
  IdentityVerificationInvalidRequestTelephoneAreaCodeMax = 'IdentityVerification:InvalidRequest:Telephone:AreaCode:Max',
  IdentityVerificationInvalidRequestTelephoneAreaCodeEmpty = 'IdentityVerification:InvalidRequest:Telephone:AreaCode:Empty',
  IdentityVerificationInvalidRequestTelephoneExchangePattern = 'IdentityVerification:InvalidRequest:Telephone:Exchange:Pattern',
  IdentityVerificationInvalidRequestTelephoneExchangeMax = 'IdentityVerification:InvalidRequest:Telephone:Exchange:Max',
  IdentityVerificationInvalidRequestTelephoneExchangeEmpty = 'IdentityVerification:InvalidRequest:Telephone:Exchange:Empty',
  IdentityVerificationInvalidRequestTelephoneNumberPattern = 'IdentityVerification:InvalidRequest:Telephone:Number:Pattern',
  IdentityVerificationInvalidRequestTelephoneNumberMax = 'IdentityVerification:InvalidRequest:Telephone:Number:Max',
  IdentityVerificationInvalidRequestTelephoneNumberEmpty = 'IdentityVerification:InvalidRequest:Telephone:Number:Empty',
  IdentityVerificationInvalidRequestTelephoneExtensionPattern = 'IdentityVerification:InvalidRequest:Telephone:Extension:Pattern',
  IdentityVerificationInvalidRequestTelephoneExtensionMax = 'IdentityVerification:InvalidRequest:Telephone:Extension:Max',
  IdentityVerificationInvalidRequestCurrentAddressAddressIdPattern = 'IdentityVerification:InvalidRequest:CurrentAddress:AddressId:Pattern',
  IdentityVerificationInvalidRequestCurrentAddressStreetNumberPattern = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetNumber:Pattern',
  IdentityVerificationInvalidRequestCurrentAddressStreetNumberMax = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetNumber:Max',
  IdentityVerificationInvalidRequestCurrentAddressStreetNamePattern = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetName:Pattern',
  IdentityVerificationInvalidRequestCurrentAddressStreetNameMax = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetName:Max',
  IdentityVerificationInvalidRequestCurrentAddressStreetAddress1Max = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetAddress1:Max',
  IdentityVerificationInvalidRequestCurrentAddressStreetAddress1Empty = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetAddress1:Empty',
  IdentityVerificationInvalidRequestCurrentAddressStreetAddress2Max = 'IdentityVerification:InvalidRequest:CurrentAddress:StreetAddress2:Max',
  IdentityVerificationInvalidRequestCurrentAddressCityMax = 'IdentityVerification:InvalidRequest:CurrentAddress:City:Max',
  IdentityVerificationInvalidRequestCurrentAddressCityEmpty = 'IdentityVerification:InvalidRequest:CurrentAddress:City:Empty',
  IdentityVerificationInvalidRequestCurrentAddressStateMax = 'IdentityVerification:InvalidRequest:CurrentAddress:State:Max',
  IdentityVerificationInvalidRequestCurrentAddressStateEmpty = 'IdentityVerification:InvalidRequest:CurrentAddress:State:Empty',
  IdentityVerificationInvalidRequestCurrentAddressZip5Max = 'IdentityVerification:InvalidRequest:CurrentAddress:Zip5:Max',
  IdentityVerificationInvalidRequestCurrentAddressZip5Empty = 'IdentityVerification:InvalidRequest:CurrentAddress:Zip5:Empty',
  IdentityVerificationInvalidRequestCurrentAddressCountyPattern = 'IdentityVerification:InvalidRequest:CurrentAddress:County:Pattern',
  IdentityVerificationInvalidRequestCurrentAddressCountyMax = 'IdentityVerification:InvalidRequest:CurrentAddress:County:Max',
  IdentityVerificationInvalidRequestCurrentAddressCountryMax = 'IdentityVerification:InvalidRequest:CurrentAddress:Country:Max',
  IdentityVerificationInvalidRequestCurrentAddressUnitNumberPattern = 'IdentityVerification:InvalidRequest:CurrentAddress:UnitNumber:Pattern',
  IdentityVerificationInvalidRequestCurrentAddressUnitNumberMax = 'IdentityVerification:InvalidRequest:CurrentAddress:UnitNumber:Max',
  IdentityVerificationInvalidRequestCurrentAddressPostalCodeMax = 'IdentityVerification:InvalidRequest:CurrentAddress:PostalCode:Max',
  IdentityVerificationInvalidRequestSSNEmpty = 'IdentityVerification:InvalidRequest:SocialSecurityNumber:Empty',
  IdentityVerificationServerTimout = 'IdentityVerification:ServerTimeout',
  IdentityVerificationAddressError = 'IdentityVerification:AddressError',
  IdentityVerificationProcessingError = 'IdentityVerification:ProcessingError',
  IdentityVerificationApplicationNotFound = 'IdentityVerification:ApplicationNotFound',
}

export enum ApplicationSupportErrorIds {
  ApplicationSubmissionResetMissingApplicationID = 'ApplicationSubmissionReset:MissingApplicationID',
  ApplicationSubmissionResetInvalidApplicationID = 'ApplicationSubmissionReset:InvalidApplicationID',
  ApplicationSubmissionResetApplicationNotFound = 'ApplicationSubmissionReset:ApplicationNotFound',
  ApplicationSubmissionResetApplicationSubmitted = 'ApplicationSubmissionReset:ApplicationSubmitted',
  ApplicationSubmissionResetApplicationCompleted = 'ApplicationSubmissionReset:ApplicationCompleted',
  ApplicationSubmissionResetNoCompletedESignCeremony = 'ApplicationSubmissionReset:NoCompletedESignCeremony',
}

export enum ApplicationCopyErrorIds {
  ApplicationCopyThresholdInDaysMissingConfigError = 'ApplicationCopy:ThresholdInDaysMissingConfigError',
  ApplicationCopyStartDateMissingConfigError = 'ApplicationCopy:StartDateMissingConfigError',
  ApplicationCopyThresholdInDaysError = 'ApplicationCopy:ThresholdInDaysError',
  ApplicationDisabledForLineOfBusiness = 'ApplicationCopy:DisabledForLineOfBusinessError',
  ApplicationCopyStartDateError = 'ApplicationCopy:StartDateError',
}

export enum ApiErrorIds {
  QuestionnaireVersionNotFoundError = 'QuestionnaireVersionNotFoundError',
  CreateNewDraftQuestionnaireError = 'CreateNewDraftQuestionnaireError',
  DuplicateSalesDecisionRulesError = 'DuplicateSalesDecisionRulesError',
  DuplicateFileTemplateRulesError = 'DuplicateFileTemplateRulesError',
  CreateNodeIdsAffectingPricingError = 'CreateNodeIdsAffectingPricingError',
}

type APIErrorsData = {
  errors: {
    errorId: ApiErrorIds;
  };
};

export function generateAPIErrorsData(errorId: ApiErrorIds): APIErrorsData {
  return {
    errors: {
      errorId: errorId,
    },
  };
}
