import { base32 } from 'rfc4648';
import nacl from 'tweetnacl';
import naclUtils from 'tweetnacl-util';

export type CryptoMaterial = {
  seed: string;
  pubkey: string;
  signature: string;
};

export type SubmitResponse = {
  id: string;
  digest: string;
  state: string;
  // TODO: Move Application type into shared/type and add it here
  application: any;
};

export type CryptoMaterialInput = {
  digest: string;
};

export function createCryptoMaterial(submitResponse: CryptoMaterialInput): CryptoMaterial {
  const msgBytes = naclUtils.decodeUTF8(submitResponse.digest);
  const seed = nacl.randomBytes(nacl.sign.seedLength);
  const keyPair = nacl.sign.keyPair.fromSeed(seed);

  const sig = nacl.sign.detached(msgBytes, keyPair.secretKey);
  const readableSeed = base32
    .stringify(seed)
    .match(/\w*/)?.[0]
    .replace(/\w{4}(?=.*\w{4})/g, '$&-');

  if (!readableSeed) {
    throw new Error('Error while creating crypto material');
  }
  const readablePubkey = naclUtils.encodeBase64(keyPair.publicKey);
  const readableSignature = naclUtils.encodeBase64(sig);
  return {
    seed: readableSeed,
    signature: readableSignature,
    pubkey: readablePubkey,
  };
}
