import type { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';

type Props<TResponseType> = {
  onFetch: () => Promise<AxiosResponse>;
  onSuccess: (response: AxiosResponse<TResponseType>) => boolean;
  onFailure?: (error: AxiosError<{ code: number; message: string }>) => void;
  interval?: number;
  retryCount?: number;
  retryOnFailure?: boolean;
  skipPolling?: boolean;
};

export function usePolling<TResponseType>(props: Props<TResponseType>): boolean {
  const { onFetch, onSuccess, onFailure, interval = 3000, retryOnFailure = false, skipPolling = false } = props;

  const [isPolling, setIsPolling] = useState(false);

  const persistedIsPolling = useRef<boolean>();
  const isMounted = useRef<boolean>();
  const poll = useRef<number | null>();
  let retryCount = props.retryCount || 0;

  persistedIsPolling.current = isPolling;

  const stopPolling = (): void => {
    if (!isMounted.current) return;
    if (poll.current) {
      clearInterval(poll.current);
      poll.current = null;
    }
    setIsPolling(false);
  };

  const runPolling = (): void => {
    const intervalId = setInterval(async () => {
      try {
        const response = await onFetch();
        const shouldKeepPolling = !onSuccess(response);

        if (!persistedIsPolling.current || !shouldKeepPolling || retryCount === 0 || skipPolling) {
          stopPolling();
        } else {
          retryCount = retryCount - 1;
        }
      } catch (error: any) {
        onFailure && onFailure(error);

        if (!persistedIsPolling.current || !retryOnFailure || retryCount === 0 || skipPolling) {
          stopPolling();
        } else {
          retryCount = retryCount - 1;
        }
      }
    }, interval);

    poll.current = intervalId as unknown as number;
  };

  const startPolling = (): void => {
    setIsPolling(true);
    runPolling();
  };

  useEffect(() => {
    isMounted.current = true;
    startPolling();
    return () => {
      stopPolling();
      isMounted.current = false;
    };
  }, []);

  return isPolling;
}
