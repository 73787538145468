import { z } from 'zod';

import { ApiErrorIds } from '../errorIds';

export const customAxiosErrorWithUnknownDataSchema = z.object({
  response: z.object({
    data: z.object({
      errors: z.object({
        errorId: z.nativeEnum(ApiErrorIds),
        data: z.unknown().optional(),
      }),
    }),
  }),
});
