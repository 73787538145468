import { SeverityLevel } from '@sentry/browser';

export const toSeverityLevel = (level: string): SeverityLevel => {
  if (
    level === 'fatal' ||
    level === 'error' ||
    level === 'warning' ||
    level === 'log' ||
    level === 'info' ||
    level === 'debug'
  ) {
    return level;
  }

  return 'log';
};
